import React from "react";
import Helmet from "react-helmet";
import { Header, Layout, BradenPhoto, Introduction } from "../components";
import styled from "styled-components";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default ({ data }) => {
    const {
        background: {
            childImageSharp: { fluid },
        },
        Image: { edges },
    } = data;

    const [image] = edges;
    const {
        childImageSharp: { fluid: bradenFluid },
    } = image.node;

    return (
        <>
            <Helmet>
                <title>Braden Chamberlain - Security Home Mortgage</title>
                <meta
                    name="description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:image:secure_url" content="https://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:image" content="http://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:title" content="Braden Chamberlain - Security Home Mortgage" />
                <meta
                    property="og:description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:url" content="https://mortgagesbybraden.netlify.app/" />
            </Helmet>
            <Layout home={true}>
                <div style={{ background: "black" }}>
                    <StyledBackgroundImage objectFit="contain" fluid={fluid}>
                        <Header />
                        <IntroWrapper>
                            <BradenWrapper>
                                <BradenPhoto fluid={bradenFluid} />
                            </BradenWrapper>
                            <Introduction />
                        </IntroWrapper>
                    </StyledBackgroundImage>
                </div>
            </Layout>
        </>
    );
};

export const query = graphql`
    query {
        background: file(relativePath: { eq: "houses.jpg" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        Image: allFile(filter: { relativePath: { regex: "/braden.png/" } }) {
            edges {
                node {
                    relativePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
    height: 100vh;
    min-height: 700px;
    &::before,
    &::after {
        filter: blur(5px) opacity(1) brightness(0.4);
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BradenWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 40%;
    margin-top: 2px;

    @media only screen and (max-width: 560px) {
        order: 1;
        max-height: 300px;
        width: 100%;
    }
`;

const IntroWrapper = styled.div`
    height: 90%;
    display: flex;

    @media only screen and (max-width: 560px) {
        flex-direction: column;
        justify-content: flex-end;
    }
`;
